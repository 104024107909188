@import 'variable';
@import 'mixin';

/* ======================================================================
 p_top
====================================================================== */

// l_main_visual_top
.l_main_visual_top {
	width: 100%;
	min-height: 1107px;
	background-image: url(../img/p_top/hd_bg.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	/* overflow-x: hidden; */
  .inner {
    width: 1100px;
    margin: 0 auto;
    padding-top: 230px;
  }
  .top {
    padding: 0 0 10px;
    border-bottom: 1px solid #fff;
    display: inline-block;
  }
  .middle {
    margin: 30px 0 0;
  }
  .bottom {
    margin: 25px 0 0; 
  }
  @include mq_max(1184) {  
    min-height: 748px;
  	background-position: bottom;
    .inner {
      padding: 100px 15px 0;
    }
  }
  
  @include sp {
    align-items: flex-start;
    position: relative;
    height: 627px;
    min-height: unset;
    padding: 60px 0 0 0;
    background-image: url(../img/p_top/hd_bg_sp.jpg);
    background-position: center;
    .inner {
      width: auto;
      padding: 80px 0 0;
      margin: 0 15px;
    }
    .midle {
      margin: 20px 0 0;
    }
    .bottom {
      margin: 16px 0 0;
      .img {
        width: 280px;
      }
    }
  }
}

.sec01 {
  position: relative;
  width: 100%;
  padding: 0 0 100px;
  margin-top: -234px;
  &:before {
    bottom: 0;
    z-index: -1;
  }
  .inner {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .ttl {
    position: relative;
    margin: 0 0 30px;
    font-size: 43px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 65px;
    .sub {
      display: block;
      color: $color_2;
      font-size: 20px;
      line-height: 30px;
      position: relative;
      .line {
        width: 93px;
        display: inline-block;
        background-color: $color_2;
        height: 1px;
        position: relative;
        vertical-align: middle;
        margin-top: -1px;
        margin-left: 23px;
      }
    }
    .main {
      margin-top: 15px;
      display: block;
      .accent {
        color: $color_2;
        display: block;
      }
    }
  }
  .p {
    font-size: 16px;
    line-height: 30px;
    & + .p {
      margin-top: 20px;
    }
  }
  .subttl {
    margin: 30px 0 0 0;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.8px;
    line-height: 34px;
    color: $color_2;
  }
  .right {
    margin-left: 60px;
    margin-right: -73px;
  }
  @include mq_max(1184) { 
    margin-top: -57px;
    .inner {
      width: 100%;
      padding: 0 15px;
      align-items: flex-start;
    }
    .left {
      width: 70%;
    }
    .right {
      margin-right: 0;
      width: 30%;
      .img {
        width: 100%;
      }
    }
  }

  @include sp {
    /*margin-top: -57px;*/
    &:before {
    }
    .inner {
      /*width: 100%;*/
      flex-direction: column;
      padding: 0;
    }
    .left {
      width: 100%;
    }
    .ttl {
      font-size: 26px;
      line-height: 40px;        
      margin: 0 15px 30px;        
      .sub {
        font-size: 16px;
        .line {
          margin-left: 15px;
        }
      }
    }
    .img {
      width: 100%;
    }
    .p {
      margin: 20px 15px 0;
      & + .p {
        margin-top: 1em;
      }
    }
    .subttl {
      margin: 1em 15px 0;
      font-size: 18px;
      line-height: 30px;
    }
    .right {
      display: none;
    }
  }
}

.sec02 {
  width: 100%;
  position: relative;
  &::after {
    width: 188px;
    height: 80px;
    clip-path: polygon(49% 100%, 0 0, 100% 0);
    background: linear-gradient(#1c65ad 0%, #1a99db 100%);
    content: "";
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);  
  }
  .inner {
    position: relative;
    min-height: 582px;
    padding: 50px 0 70px;
    &::after {
      background-image: url(../img/common/w_bg01.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #0D5DAB;
      border-radius: 10px 0 0 10px;
      margin-left: 60px;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 60px);
      height: 100%;
      z-index: -1;
    }
  }
  .ttl {
    margin: 0 0 40px;
    font-size: 43px;
    font-weight: bold;
    line-height: 62px;
    text-align: center;
    color: #fff;
    .accent {
      background: url(../img/common/accent_line01.png);
      background-repeat: no-repeat;
      background-position: bottom left;
    }
  }
  .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 1160px;
    margin: 0 auto;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 360px;
      text-align: center;
      .img {
        border-radius: 10px 10px 0 0;
      }
      &.item:nth-of-type(n+2) {
        margin-left: 40px;
      }
      .txt {
        width: 100%;
        padding: 23px 8px;
        border-radius: 10px;
        font-size: 16px;
        line-height: 27px;
        background: $color_2;
        border-radius: 0 0 10px 10px;
        color: #fff;
        font-weight: bold;
        .highlight {
          color: $color_3;
        }
      }
    }
  }
  
  @include mq_max(1320) {
  /*@include mq_max(1184) {*/
    .inner {
      padding: 50px 15px 70px;
      &::after {
        width: 100%;
        margin-left: 0;
        border-radius: 0;
      }
    }
    .list {
      width: 100%;
      .item {
        width: calc(100% / 3);
        .img {
          width: 100%;
        }
        .txt {
          height: 100%;
        }
      }
    }
  }

  @include sp {  
    &::after {
      width: 136px;
      height: 58px;
      clip-path: polygon(49% 100%, 0 0, 100% 0);
      bottom: -33px;
    }
    .inner {
      min-height: 584px;
      padding: 50px 15px 0;
      &::after {
        background-size: 73.33% auto;
        border-radius: 0;
        margin-left: 0;
        width: 100%;
      }
    }
    .ttl {
      font-size: 26px;
      line-height: 40px;
      .accent {
        background: url(../img/common/accent_line01.png);
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
      }
    }
    .list {
      flex-direction: column;
      width: 100%;
      .item {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        border-radius: 6px;
        &:nth-of-type(n+2) {
          margin-top: 15px;
        }
        .photo {
          width: 140px;
        }
        .img {
          border-radius: 6px 0 0 6px;
          width: 100%;
        }
        &.item:nth-of-type(n+2) {
          margin-left: 0;
        }
        .txt {
          width: 205px;
          height: auto;
          padding: 10px 15px;
          border-radius: 0 6px 6px 0;
          line-height: 23px;
          text-align: left;
          display: flex;
          align-items: center;
        }
      }
    }
  
  }
  @include mq_max(360) {
    .list {
      flex-direction: column;
      width: 100%;
      .item {
        .txt {
          font-size: 4.167vw;
        }
      }
    }
  }
  @include mq_max(339) {
    .list {
      .item {
        .txt {
          font-size: 12px;
        }
      }
    }
  }
}

.sec03 {
  width: 100%;
  background-image: url(../img/p_top/s_bg01.svg), url(../img/p_top/s_bg02.svg), url(../img/p_top/s_bg03.svg);
  background-repeat: no-repeat;
  background-position: top 30px right, top 588px left, bottom left;
  padding: 120px 0 70px;
  .ttl {
    position: relative;
    margin: 0 0 30px;
    font-size: 43px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 65px;
    max-width: 1100px;
    margin: 0 auto 80px;
    .sub {
      display: block;
      color: #032076;
      font-size: 20px;
      line-height: 30px;
      position: relative;
      .line {
        width: 93px;
        display: inline-block;
        background-color: #032076;
        height: 1px;
        position: relative;
        vertical-align: middle;
        margin-top: -1px;
        margin-left: 23px;
      }
    }
    .main {
      margin-top: 15px;
      display: block;
      .accent {
        color: #032076;
      }
    }
  }
  .inner {
    max-width: 1180px;
    margin: 0 auto;
  }
  .list {
    .item {
      display: flex;
      align-items: center;
      text-align: left;
      &:nth-of-type(n+2) {
        margin-top: 50px;
      }
      .box {
        background-color: #fff;
        border-radius: 8px;
        width: 664px;
        z-index: 1;
        min-height: 260px;
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        min-width: 664px;
        box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.08);
        padding: 40px;
        @include ie {
          height: 388px;
        }
        &.right {
          margin-left: -78px;
        } 
        &.left {
          margin-right: -78px;
        }
        .subttl {
          font-size: 30px;
          line-height: 40px;
          color: $color_2;
          font-weight: bold;
          margin-bottom: 20px;
          .num {
            color: #fff;
            background-color: $color_2;
            font-family: $font_2;
            font-size: 24px;
            width: 40px;
            height: 40px;
            display: inline-block;
            text-align: center;
            vertical-align: top;
            margin-top: 2px;
          }
          .txt {
            padding: 0 0 0 10px;
            display: inline-block;
            line-height: 40px;
          }
        }
      }
      .photo {
        .img {
          width: 597px;
          border-radius: 10px;
        }
      }
    }
    .p {
      font-size: 18px;
      line-height: 30px;
      &:nth-of-type(n+2) {
        margin-top: 1em;
      }
    }
  }
  .link {
    margin-top: 70px;
  }

  @include mq_max(1184) {
    .ttl {
      max-width: unset;
      padding: 0 15px;
    }
    .inner {
      max-width: unset;
      padding: 0 15px;
    }
    .list {
      .item {
        .box {
          width: auto;
          min-width: unset;
        }
        .photo {
          min-width: 300px;
          .img {
            width: 100%;
          }
        }
      }
    }
  }

  @include sp {
    width: 100%;
    background-image: url(../img/p_top/s_bg01_sp.svg), url(../img/p_top/s_bg03_sp.svg);
    background-size: 153px auto , contain;
    background-position: top 30px right, bottom left;
    padding: 83px 0 50px;
    .inner {
      margin: 0 auto;
      padding: 0;
    }
    .ttl {
      font-size: 26px;
      line-height: 40px;        
      margin: 0 15px 30px;
      padding: 0;
      .sub {
        font-size: 16px;
        .line {
          margin-left: 15px;
        }
      }
    }
    .list {
      .item {
        flex-direction: column;
        &:nth-of-type(n+2) {
          margin-top: 30px;
        }
        .box {
          width: 92%;
          min-height: unset;
          min-width: unset;
          box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.08);
          padding: 20px;
          margin: -20px 0 0;
          order: 2;
          &.right {
            margin-left: 0;
          } 
          &.left {
            margin-right: 0;
          }
          .subttl {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 15px;
            .num {
              font-size: 24px;
              line-height: 40px;
            }
            .txt {
              padding: 0 0 0 10px;
              width: calc(100% - 50px);
            }
          }
        }
        .photo {
          order: 1;
          .img {
            width: 100%;
            border-radius: 0;
          }
        }
      }
    }
    .link {
      margin-top: 40px;
      width: calc(100% - 30px)
    }
  }
}

.sec04 {
  width: 100%;
  min-height: 926px;
  background-image: url(../img/common/p_bg01.png), linear-gradient(135deg, #0d5dab 0%, #1a99db 100%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 70px 0;
  .inner {
    position: relative;
    max-width: $content_width;
    margin: 0 auto;
  }
  .ttl {
    margin: 0 0 40px;
    font-size: 43px;
    font-weight: bold;
    line-height: 62px;
    text-align: center;
    color: #fff;
    position: relative;
    .sub {
      position: relative;
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 13px;
      margin-bottom: 13px;
      display: block;      
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 93px;
        background-color: #fff;
      }
    }
    .main {
      display: block;
    }
  }
  .p {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
  }
  .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 40px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 340px;
      text-align: center;
      border-radius: 10px;
      position: relative;
      .photo {
        .link {
          display: block;
          margin: 0;
          overflow: hidden;
          border-radius: 10px;
          z-index: 1;
          &:hover .img{
          }
        }
      }
      .img {
        border-radius: 10px;
        transform: translate3d(0,0,0) scale(1.00);
        transition: transform .7s cubic-bezier(.19,.82,.27,1);
      }
      &.item:nth-of-type(n+2) {
        margin-left: 20px;
      }
      .txt {
        width: 100%;
        /*font-size: 26px;*/
        font-size: 20px;
        line-height: 36px;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .link {
          position: relative;
          padding-right: 34px;
          color: #fff;
          font-weight: bold;
          display: inline-block;
          background-color: transparent;
          &:after {
            background-image: url(../img/common/arr_icon01.svg);
            background-size: contain;
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 24px;
            width: 24px;
            transform: rotate(45deg);
            position: absolute;
            top: 20px;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .link {
    margin-top: 70px;
  }
  
  @include pc_tab {
    .item {
      .photo {
        .link {
          background-color: #000;
          .img {
            opacity: 0.8;
            transition: .4s;
          }
          &:hover .img{
            transform: translate3d(0,0,0) scale(1.07);
            transition: transform .4s;
            opacity: 1;
          }
        }
      }
    }
  }
  
  @include mq_max(1184) {  
    .inner {
      max-width: unset;
      padding: 0 15px;
    }
    .list {
      .item {
        width: calc((100% - 60px) / 4);
        .img {
          width: 100%;
        }
        .txt {
          font-size: 18px;
        }
      }
    }  
  }
  
  @include sp {
    padding: 50px 0;
    .inner {
      position: relative;
      max-width: $content_width;
      margin: 0 15px;
      padding: 0;
    }
    .ttl {
      margin: 0 0 30px;
      font-size: 26px;
      line-height: 40px;
      .sub {
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:before {
          width: 53px;
        }
      }
    }
    .list {
      flex-direction: column;
      margin-top: 30px;
      .item {
        flex-direction: column;
        width: 100%;
        border-radius: 6px;
        &:nth-of-type(n+2) {
          margin-top: 10PX;
        }
        .photo {
          .link {
            position: relative;
            display: block;
            margin: 0;
            overflow: hidden;
            border-radius: 10px;
            z-index: 1;
          }
        }
        .img {
          width: 100%;
        }
        &.item:nth-of-type(n+2) {
          margin-left: 0;
        }
        .txt {
          font-size: 20px;
          line-height: 30px;
          .link {
            display: inline-block;
            &:after {
              top: 50%;
            }
          }
        }
      }
    }
    .link {
      margin-top: 40px;
    }
  }
}

.sec06 {
  position: relative;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FAFAFA;
    height: 100%;
    width: calc((100% - 1100px) / 2);
    z-index: -1;
  }
  .inner {
    max-width: $content_width;
    margin: 0 auto;
    position: relative;
    padding: 70px 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FAFAFA;
      height: 100%;
      width: 86.27%;
      z-index: -1;
    }
  }
  .ttl {
    margin-top: 0;
  }
  .information {
    display: flex;
    .list {
      width: 576px;
    }  
    .photo {
      margin: 0 0 0 40px;
      .img {
        width: 484px;
      }
    }
  }
  .link {
    margin-top: 70px;
  }

  @include mq_max(1184) {  
    .inner {
      max-width: unset;
      padding: 70px 15px;
    }
    .information {
      .photo {
        .img {
          width: 100%;
        }
      }
    }
  }

  @include sp {
    padding: 50px 0;
    background-color: #FAFAFA;
    &::before {
      content: none;
    }
    .inner {
      margin: 0 15px;
      padding: 0;
      &::before {
        content: none;
      }
    }
    .ttl {
      margin-top: 0;
    }
    .information {
      flex-direction: column;
      .list {
        width: 100%;
      }  
      .photo {
        display: none;
      }
    }
    .link {
      margin-top: 40px;
    }
  }
}

.support_sec {
  margin-top: 100px;
  @include sp {
    margin-top: 50px;
  }
}


